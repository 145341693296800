class EntityService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async getEntities() {

    const entities = [
      {
        "_id" : "5ff776579a5a5e24600708dd",
        "adress" : "3 Avenue d'Argenteuil",
        "country" : "France",
        "status" : "ENABLED",
        "town" : "Asnières-sur-seine",
        "label" : "BXP",
        "zipCode" : "92600"
      }
    ]

    await new Promise(resolve => setTimeout(resolve, 100));
    return entities;

    // const request = {
    //   method: 'GET',
    //   url: '/api/entities',
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async postEntities(entity) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return entity;

    // const request = {
    //   method: 'POST',
    //   url: '/api/entities',
    //   data: entity,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async deleteEntities(entityId) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return entityId;

    // const request = {
    //   method: 'DELETE',
    //   url: `/api/entities/${entityId}`,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async patchEntities(entityId, entity) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return { entityId, entity };

    // const request = {
    //   method: 'PATCH',
    //   url: `/api/entities/${entityId}`,
    //   data: entity,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }
}

export default EntityService;
