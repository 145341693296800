class RoleService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async getRoles() {

    const roles = [
      {
        "_id" : "ADMIN",
        "permissions" : [
          "ADMIN"
        ],
        "scopes" : [
    
        ]
      }
    ]

    await new Promise(resolve => setTimeout(resolve, 100));
    return roles;

    // const request = {
    //   method: 'GET',
    //   url: '/iam/roles',
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async postRoles(role) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return role;

    // const request = {
    //   method: 'POST',
    //   url: '/iam/roles',
    //   data: role,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async deleteRoles(roleId) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return roleId;

    // const request = {
    //   method: 'DELETE',
    //   url: `/iam/roles/${roleId}`,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async patchRoles(roleId, role) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return { roleId, role };

    // const request = {
    //   method: 'PATCH',
    //   url: `/iam/roles/${roleId}`,
    //   data: role,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }
}

export default RoleService;
