import jwt_decode from 'jwt-decode'

const TOKEN_KEY = 'TOKEN_BAUX';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_BAUX';

class TokenStorageService {
  static getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  static getRefresh() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  static saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  }

  static saveRefreshToken(accessToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, accessToken);
  }

  static removeToken() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  static getTokenPayload() {
    let payload = {};
    const token = TokenStorageService.getToken();
    if (token) payload = jwt_decode(token);
    return payload;
  }

  static getRefreshPayload() {
    let payload = {};
    const token = TokenStorageService.getRefresh();
    if (token) payload = jwt_decode(token);
    return payload;
  }
}

export default TokenStorageService;
