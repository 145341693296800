class UserService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async getUsers() {
    await new Promise(resolve => setTimeout(resolve, 100));
    return [
      {
        "username" : "vincweb",
        "lastname" : "caudron",
        "email" : "vincent@caudronengineering.com",
        "job" : "admin",
        "roles" : [
          "ADMIN"
        ],
        "firstname" : "vincent"
      },
      {
        "username" : "clara",
        "lastname" : "Maquet",
        "email" : "clara.maquet@bxp-conseil.fr",
        "job" : "Testeur",
        "roles" : [
          "ADMIN"
        ],
        "firstname" : "Clara"
      },
      {
        "username" : "admin",
        "lastname" : "conseil",
        "email" : "app@bxp-conseil.fr",
        "job" : "admin",
        "roles" : [
          "ADMIN"
        ],
        "firstname" : "bxp"
      }
    ];

    // const request = {
    //   method: 'GET',
    //   url: '/iam/users',
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async deleteUsers(username) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return username;

    // const request = {
    //   method: 'DELETE',
    //   url: `/iam/users/${username}`,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async postUsers(newUser) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return newUser;

    // const request = {
    //   method: 'POST',
    //   url: '/iam/users',
    //   data: newUser,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async patchUsers(username, user) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return { username, user };

    // const request = {
    //   method: 'PATCH',
    //   url: `/iam/users/${username}`,
    //   data: user,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }
}

export default UserService;
