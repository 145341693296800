class ScopeService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async getScopes() {

    const scopes = [
      {
        "_id" : "NORD",
        "label" : "Nord"
      },
      {
        "_id" : "SUD",
        "label" : "Sud"
      },
      {
        "_id" : "FRANCE",
        "label" : "France"
      }
    ]

    await new Promise(resolve => setTimeout(resolve, 100));
    return scopes;
    
    // const request = {
    //   method: 'GET',
    //   url: '/api/scopes',
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async postScopes(scope) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return scope;

    // const request = {
    //   method: 'POST',
    //   url: '/api/scopes',
    //   data: scope,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async deleteScopes(scopeId) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return scopeId;

    // const request = {
    //   method: 'DELETE',
    //   url: `/api/scopes/${scopeId}`,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async patchScopes(scopeId, scope) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return { scopeId, scope };
    
    // const request = {
    //   method: 'PATCH',
    //   url: `/api/scopes/${scopeId}`,
    //   data: scope,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }
}

export default ScopeService;
