import Axios from 'axios';
import mock000008630 from './mock/000008630.json';
import mock001617112 from './mock/001617112.json';
import mock001532540 from './mock/001532540.json';

class InseeService {
  constructor({ config }) {
    this.axios = Axios.create({
      timeout: config.timeout || 10000,
      headers: { Authorization: `Bearer ${config.token}` },
    });
  }

  async getIndex(idbank) {

    await new Promise(resolve => setTimeout(resolve, 1000));
    if(idbank === '000008630') {
      return { data: mock000008630.xml};
    }
    if(idbank === '001617112') {
      return { data: mock001617112.xml};
    }
    if(idbank === '001532540') {
      return { data: mock001532540.xml};
    }

    // // eslint-disable-next-line no-useless-catch
    // try {
    //   const response = await this.axios({
    //     url: `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/${idbank}`,
    //     method: 'GET',
    //   });

    //   return response;
    // } catch (error) {
    //   throw error;
    // }
  }
}

export default InseeService;
