class LeaseService {
  constructor({ apiClient, uploadService }) {
    this.apiClient = apiClient;
    this.uploadService = uploadService;
  }

  async getLeases(params) {

    /* eslint-disable */
    const leases = [
      {
        "_id" : "629e47d991ec610001d9ed53",
        "taxes" : [
    
        ],
        "country" : "Canada",
        "indexIndexation" : "ICC",
        "rentYear" : 1200,
        "charges" : [
    
        ],
        "rentGlobal" : 1200,
        "scope" : {
          "_id" : "NORD",
          "label" : "Nord"
        },
        "quarterIndexation" : "1",
        "zipCode" : "Y1A6C4",
        "area" : 50,
        "documents" : [
    
        ],
        "totalArea" : 50,
        "rent" : [
          {
            "year" : 2022,
            "value" : 1200
          }
        ],
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "status" : "ENABLED",
        "dateRelease" : [
          {
            "date" : "2022-06-21T00:00:00.000Z",
            "thoughtfulness" : "1d",
            "notice" : "1m"
          }
        ],
        "leaseType" : "COMMERCIAL",
        "activity" : [
    
        ],
        "rentArea" : "24.00",
        "lessor" : "UPS Store",
        "warranty" : [
    
        ],
        "town" : "WHITEHORSE",
        "leaseStart" : "2022-06-08T00:00:00.000Z",
        "accessory" : [
    
        ],
        "indexation" : "YEAR",
        "adress" : "108 Elliot st",
        "leaseDuration" : "2m",
        "referenceIndex" : 1770,
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2022-05-21T00:00:00.000Z"
          }
        ],
        "todos" : [
    
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2022-05-20T00:00:00.000Z"
          }
        ],
        "leaseEnd" : "2022-08-17T00:00:00.000Z"
      },
      {
        "_id" : "6230922420bfc100011cce70",
        "taxes" : [
          {
            "year" : 2022,
            "value" : 5000
          },
          {
            "year" : 2019,
            "value" : 567
          }
        ],
        "statusDate" : null,
        "country" : "France",
        "indexIndexation" : "ILAT",
        "rentYear" : 62000,
        "signWarranty" : 15000,
        "charges" : [
          {
            "year" : 2022,
            "value" : 5000
          }
        ],
        "rentGlobal" : 72000,
        "keepWarranty" : "KEEP",
        "activityArea" : 200,
        "scope" : {
          "_id" : "FRANCE",
          "label" : "France"
        },
        "zipCode" : "75008",
        "area" : 355,
        "restoration" : "Oui",
        "signRent" : 0,
        "documents" : [
    
        ],
        "restitution" : "Très bon état",
        "signAccessory" : 0,
        "rent" : [
          {
            "year" : 2022,
            "value" : 57000
          }
        ],
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "totalArea" : 555,
        "status" : "ENABLED",
        "dateRelease" : [
          {
            "date" : "2031-03-14T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2028-03-14T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2025-03-14T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          }
        ],
        "leaseType" : "COMMERCIAL",
        "activity" : [
          {
            "year" : 2022,
            "value" : 3000
          }
        ],
        "rentArea" : "129.73",
        "lessor" : "LA FRANCAISE",
        "warranty" : [
          {
            "year" : 2022,
            "value" : 4000
          },
          {
            "year" : 2019,
            "value" : 2345
          }
        ],
        "town" : "PARIS",
        "leaseStart" : "2022-03-15T00:00:00.000Z",
        "accessory" : [
          {
            "year" : 2022,
            "value" : 2000
          }
        ],
        "parking" : 0,
        "adress" : "3 rue Chauveau Largarde",
        "leaseDuration" : "9y",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2030-09-14T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2027-09-14T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2024-09-14T00:00:00.000Z"
          }
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2030-03-14T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2027-03-14T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2024-03-14T00:00:00.000Z"
          }
        ],
        "todos" : [
          {
            "_id" : "625e656562b8d5000151aa35",
            "date" : "2022-04-19T00:00:00.000Z",
            "message" : "Tester le bail"
          }
        ],
        "leaseEnd" : "2031-03-14T00:00:00.000Z"
      },
      {
        "_id" : "60508505650f070001d311a8",
        "taxes" : [
          {
            "year" : 2021,
            "value" : 2000
          }
        ],
        "statusDate" : null,
        "country" : "France",
        "indexIndexation" : "ICC",
        "rentYear" : 5400,
        "initWarranty" : 530,
        "signWarranty" : 4500,
        "charges" : [
    
        ],
        "initWarranty_commentary" : "Un commentaire",
        "rentGlobal" : 7400,
        "keepWarranty" : "DONE",
        "activityArea" : 12,
        "currentIndex" : 1822,
        "scope" : {
          "_id" : "NORD",
          "label" : "Nord"
        },
        "quarterIndexation" : "1",
        "zipCode" : "59800",
        "area" : 250,
        "documents" : [
          {
            "link" : "dev\/60508505650f070001d311a8\/62c7ca81e7e737e3cec0a8ba.jpeg",
            "label" : "USA",
            "_id" : "62c7ca81e7e737e3cec0a8ba",
            "category" : "annexe",
            "date" : "2021-02-24T00:00:00.000Z"
          },
          {
            "link" : "dev\/60508505650f070001d311a8\/6050858f72a4f40001843bf5.png",
            "label" : "Capture d'écran (titre modifié)",
            "_id" : "6050858f72a4f40001843bf5",
            "category" : "lease",
            "date" : "2022-11-17T00:00:00.000Z"
          }
        ],
        "totalArea" : 262,
        "signAccessory" : 1000,
        "rent" : [
          {
            "year" : 2021,
            "value" : 2400
          }
        ],
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "status" : "ENABLED",
        "dateRelease" : [
          {
            "date" : "2024-03-15T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2023-03-15T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2022-04-15T00:00:00.000Z",
            "thoughtfulness" : "1m",
            "notice" : "1m"
          }
        ],
        "leaseType" : "COMMERCIAL",
        "activity" : [
          {
            "year" : 2021,
            "value" : 2000
          }
        ],
        "rentArea" : "28.24",
        "lessor" : "Foncière Etoile",
        "warranty" : [
    
        ],
        "town" : "LILLE",
        "leaseStart" : "2021-03-19T00:00:00.000Z",
        "accessory" : [
          {
            "year" : 2021,
            "value" : 1000
          }
        ],
        "parking" : 5,
        "adress" : "3 place de la gare",
        "leaseDuration" : "9y",
        "indexation" : "YEAR",
        "rentFree_commentary" : "3 mois",
        "todos" : [
          {
            "done" : true,
            "message" : "GED en PPROD",
            "_id" : "605085f672a4f40001843bf6",
            "date" : "2021-03-16T00:00:00.000Z"
          },
          {
            "done" : true,
            "message" : "Test",
            "_id" : "62b1f29bb0c3df77e9566581",
            "date" : "2022-06-01T00:00:00.000Z"
          }
        ],
        "referenceIndex" : 1770,
        "otherArea" : 50,
        "work606" : "FOR_RENTER",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2023-09-15T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2022-09-15T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2022-03-15T00:00:00.000Z"
          }
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2023-03-15T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2022-03-15T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2022-02-15T00:00:00.000Z"
          }
        ],
        "leaseEnd" : "2030-03-15T00:00:00.000Z",
        "particularClauses_commentary" : "On test"
      },
      {
        "leaseStart" : "2022-06-15T00:00:00.000Z",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2022-03-15T00:00:00.000Z"
          }
        ],
        "status" : "ENABLED",
        "zipCode" : "K1K4R4",
        "dateRelease" : [
          {
            "date" : "2022-06-15T00:00:00.000Z",
            "thoughtfulness" : "1m",
            "notice" : "3m"
          }
        ],
        "country" : "Canada",
        "documents" : [
    
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2022-02-15T00:00:00.000Z"
          }
        ],
        "_id" : "62a75b9402b465af2bd8c231",
        "totalArea" : 120,
        "rentArea" : "19.17",
        "activity" : [
    
        ],
        "rent" : [
          {
            "year" : 2022,
            "value" : 2300
          }
        ],
        "town" : "VANIER",
        "todos" : [
    
        ],
        "charges" : [
    
        ],
        "leaseDuration" : "3y",
        "warranty" : [
    
        ],
        "rentYear" : 2300,
        "scope" : {
          "_id" : "NORD",
          "label" : "Nord"
        },
        "leaseEnd" : "2025-02-05T00:00:00.000Z",
        "taxes" : [
    
        ],
        "accessory" : [
    
        ],
        "rentGlobal" : 2300,
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "adress" : "532 Montreal RD #445",
        "area" : 120,
        "lessor" : "Moii",
        "leaseType" : "CIVIL"
      },
      {
        "_id" : "608bf9ed9c614600017a0976",
        "taxes" : [
          {
            "year" : 2021,
            "value" : 9000
          }
        ],
        "statusDate" : "2022-03-17T00:00:00.000Z",
        "country" : "France",
        "indexIndexation" : "ILAT",
        "rentYear" : 158000,
        "charges" : [
          {
            "year" : 2021,
            "value" : 4500
          }
        ],
        "rentGlobal" : 171500,
        "keepWarranty" : "DONE",
        "currentIndex" : 117,
        "scope" : {
          "_id" : "FRANCE",
          "label" : "France"
        },
        "quarterIndexation" : "2",
        "zipCode" : "75010",
        "area" : 300,
        "signRent" : 156000,
        "documents" : [
    
        ],
        "signRentRate" : 0,
        "signAccessory" : 1,
        "rent" : [
          {
            "year" : 2021,
            "value" : 156000
          }
        ],
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "totalArea" : 300,
        "status" : "DISABLED",
        "dateRelease" : [
          {
            "date" : "2029-03-14T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2026-03-14T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2023-03-14T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2022-03-14T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          }
        ],
        "leaseType" : "COMMERCIAL",
        "activity" : [
          {
            "year" : 2021,
            "value" : 2000
          }
        ],
        "rentArea" : "571.67",
        "lessor" : "SCI Champs Elysées",
        "warranty" : [
    
        ],
        "town" : "PARIS",
        "leaseStart" : "2020-03-15T00:00:00.000Z",
        "signCharges" : 4500,
        "parking" : 3,
        "adress" : "54 rue de Paradis",
        "leaseDuration" : "9y",
        "indexation" : "YEAR",
        "rentFree_commentary" : "3 mois",
        "referenceIndex" : 115,
        "accessory" : [
    
        ],
        "todos" : [
          {
            "date" : "2021-04-29T00:00:00.000Z",
            "message" : "renouveler le contrat d'entretien",
            "_id" : "608bfdf6adaf4c00010d1699"
          },
          {
            "date" : "2022-06-24T00:00:00.000Z",
            "message" : "Test",
            "_id" : "62b209aec70d10b2763412cb"
          }
        ],
        "otherArea" : 0,
        "work606" : "FOR_RENTER",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2028-09-14T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2025-09-14T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2022-09-14T00:00:00.000Z"
          },
          {
            "index" : 4,
            "value" : "2021-09-14T00:00:00.000Z"
          }
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2028-03-14T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2025-03-14T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2022-03-14T00:00:00.000Z"
          },
          {
            "index" : 4,
            "value" : "2021-03-14T00:00:00.000Z"
          }
        ],
        "signTaxes" : 9000,
        "leaseEnd" : "2022-03-02T00:00:00.000Z"
      },
      {
        "_id" : "625e662d62b8d5000151aa36",
        "signActivities" : 2000,
        "taxes" : [
          {
            "year" : 2022,
            "value" : 2200
          }
        ],
        "country" : "France",
        "rentYear" : 15100,
        "signWarranty" : 5000,
        "charges" : [
          {
            "year" : 2022,
            "value" : 1700
          }
        ],
        "rentGlobal" : 19000,
        "keepWarranty" : "DONE",
        "activityArea" : 40,
        "scope" : {
          "_id" : "FRANCE",
          "label" : "France"
        },
        "zipCode" : "75008",
        "area" : 160,
        "signRent" : 10000,
        "documents" : [
    
        ],
        "totalArea" : 200,
        "rent" : [
          {
            "year" : 2022,
            "value" : 13000
          }
        ],
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "status" : "ENABLED",
        "dateRelease" : [
          {
            "date" : "2022-04-17T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2019-04-17T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          },
          {
            "date" : "2016-04-17T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          }
        ],
        "leaseType" : "COMMERCIAL",
        "activity" : [
          {
            "year" : 2022,
            "value" : 2100
          }
        ],
        "rentArea" : "95.00",
        "lessor" : "SCI Haussamann",
        "warranty" : [
          {
            "year" : 2022,
            "value" : 6000
          }
        ],
        "town" : "PARIS",
        "leaseStart" : "2013-04-18T00:00:00.000Z",
        "signCharges" : 1500,
        "parking" : 2,
        "adress" : "128 Boulevard Haussmann",
        "leaseDuration" : "9y",
        "accessory" : [
    
        ],
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2021-10-17T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2018-10-17T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2015-10-17T00:00:00.000Z"
          }
        ],
        "todos" : [
    
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2021-04-17T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2018-04-17T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2015-04-17T00:00:00.000Z"
          }
        ],
        "otherArea" : 20,
        "signTaxes" : 2000,
        "leaseEnd" : "2022-04-17T00:00:00.000Z"
      },
      {
        "leaseStart" : "2021-06-16T00:00:00.000Z",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2022-11-10T00:00:00.000Z"
          }
        ],
        "status" : "DISABLED",
        "zipCode" : "59800",
        "dateRelease" : [
          {
            "date" : "2023-05-10T00:00:00.000Z",
            "thoughtfulness" : "6m",
            "notice" : "6m"
          }
        ],
        "country" : "France",
        "documents" : [
          {
            "label" : "Billet d'avion",
            "_id" : "60ca1914ad2e050001994024",
            "category" : "other",
            "link" : "dev\/60ca188eb4610e000162e60f\/60ca1914ad2e050001994024.png"
          }
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2022-05-10T00:00:00.000Z"
          }
        ],
        "_id" : "60ca188eb4610e000162e60f",
        "totalArea" : 21,
        "rentArea" : "40.48",
        "activity" : [
    
        ],
        "rent" : [
          {
            "year" : 2021,
            "value" : 850
          }
        ],
        "town" : "LILLE",
        "todos" : [
    
        ],
        "charges" : [
    
        ],
        "leaseDuration" : "3y",
        "keepWarranty" : "KEEP",
        "warranty" : [
    
        ],
        "scope" : {
          "_id" : "NORD",
          "label" : "Nord"
        },
        "leaseEnd" : "2024-02-08T00:00:00.000Z",
        "taxes" : [
    
        ],
        "accessory" : [
    
        ],
        "rentYear" : 850,
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "rentGlobal" : 850,
        "adress" : "3 place de la gare",
        "area" : 21,
        "lessor" : "Bailleur",
        "leaseType" : "OVER"
      },
      {
        "_id" : "6044b4cc4bdc9564e2a52978",
        "taxes" : [
          {
            "year" : 2021,
            "value" : 234
          }
        ],
        "statusDate" : null,
        "country" : "France",
        "indexIndexation" : "ICC",
        "rentYear" : 6201,
        "signWarranty" : 5000,
        "charges" : [
    
        ],
        "rentGlobal" : 6435,
        "keepWarranty" : "DONE",
        "activityArea" : 0,
        "currentIndex" : 19,
        "workDisrepair" : "FOR_TENOR",
        "scope" : {
          "_id" : "NORD",
          "label" : "Nord"
        },
        "quarterIndexation" : "2",
        "zipCode" : "59151",
        "area" : 342,
        "documents" : [
          {
            "link" : "dev\/6044b4cc4bdc9564e2a52978\/60c8d9e581491b000188c0ef.png",
            "label" : "Billet d'avion",
            "_id" : "60c8d9e581491b000188c0ef",
            "category" : "contrat",
            "date" : "2022-03-29T00:00:00.000Z"
          },
          {
            "link" : "dev\/6044b4cc4bdc9564e2a52978\/605084e2650f070001d311a7.png",
            "label" : "Bon d'achat",
            "_id" : "605084e2650f070001d311a7",
            "category" : "other",
            "date" : "2022-03-23T00:00:00.000Z"
          }
        ],
        "totalArea" : 342,
        "rent" : [
          {
            "year" : 2021,
            "value" : 5400
          }
        ],
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "status" : "ENABLED",
        "dateRelease" : [
          {
            "date" : "2022-03-31T00:00:00.000Z",
            "thoughtfulness" : "2d",
            "notice" : "5m"
          },
          {
            "date" : "2021-06-16T00:00:00.000Z",
            "thoughtfulness" : "2m",
            "notice" : "3m"
          },
          {
            "date" : "2021-03-09T00:00:00.000Z",
            "thoughtfulness" : "5m",
            "notice" : "5m"
          },
          {
            "date" : "2017-02-01T00:00:00.000Z",
            "thoughtfulness" : "1m",
            "notice" : "3m"
          }
        ],
        "leaseType" : "PRO",
        "activity" : [
          {
            "year" : 2020,
            "value" : 567
          }
        ],
        "workConforme" : "FOR_RENTER",
        "lessor" : "Vincent",
        "warranty" : [
          {
            "year" : 2020,
            "value" : 345
          }
        ],
        "town" : "ARLEUX",
        "leaseStart" : "2021-03-08T00:00:00.000Z",
        "accessory" : [
          {
            "year" : 2021,
            "value" : 234
          }
        ],
        "parking" : 0,
        "adress" : "15 rue jacques duclos",
        "leaseDuration" : "5y",
        "indexation" : "YEAR",
        "referenceIndex" : 23,
        "todos" : [
          {
            "done" : false,
            "message" : "test avec une autre description",
            "_id" : "609d47e73275c70001c64fff",
            "date" : "2021-05-13T00:00:00.000Z"
          }
        ],
        "work606" : "false",
        "otherArea" : 20,
        "rentArea" : "18.82",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2021-10-31T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2021-03-16T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2020-10-09T00:00:00.000Z"
          },
          {
            "index" : 4,
            "value" : "2016-11-01T00:00:00.000Z"
          }
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2021-10-29T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2021-01-16T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2020-05-09T00:00:00.000Z"
          },
          {
            "index" : 4,
            "value" : "2016-10-01T00:00:00.000Z"
          }
        ],
        "signIn" : 234,
        "leaseEnd" : "2026-03-07T00:00:00.000Z",
        "particularClauses_commentary" : "Un commentaire\nsur plusieurs\nlignes\net\nencore\nplus de ligne"
      },
      {
        "_id" : "6050864a72a4f40001843bf7",
        "taxes" : [
    
        ],
        "statusDate" : "2021-03-31T00:00:00.000Z",
        "country" : "France",
        "rentYear" : 3000,
        "charges" : [
    
        ],
        "otherConditions_commentary" : "Un commentaire ajouté à la création",
        "rentGlobal" : 3000,
        "keepWarranty" : "KEEP",
        "workDisrepair" : "FOR_TENOR",
        "workMaintenance" : "FOR_RENTER",
        "scope" : {
          "_id" : "NORD",
          "label" : "Nord"
        },
        "zipCode" : "59800",
        "area" : 42,
        "documents" : [
          {
            "link" : "dev\/6050864a72a4f40001843bf7\/61e36b616ccea400016c92b1.pdf",
            "label" : "Evolution",
            "_id" : "61e36b616ccea400016c92b1",
            "category" : "annexe",
            "date" : "2022-01-20T00:00:00.000Z"
          },
          {
            "date" : "2022-01-05T00:00:00.000Z",
            "label" : "Logo",
            "_id" : "605086d772a4f40001843bf8",
            "category" : "plan",
            "link" : "dev\/6050864a72a4f40001843bf7\/605086d772a4f40001843bf8.png"
          },
          {
            "link" : "dev\/6050864a72a4f40001843bf7\/61e384033d00220001131c56.xlsx",
            "label" : "Autre plan",
            "_id" : "61e384033d00220001131c56",
            "category" : "plan",
            "date" : "2024-02-08T00:00:00.000Z"
          },
          {
            "link" : "dev\/6050864a72a4f40001843bf7\/61e38510a36f1d00017f39ea.jpeg",
            "label" : "captainObvious",
            "_id" : "61e38510a36f1d00017f39ea",
            "category" : "plan",
            "date" : "2022-01-13T00:00:00.000Z"
          },
          {
            "date" : "2020-02-05T00:00:00.000Z",
            "label" : "plan des locaux",
            "_id" : "605220cd1a5c6e00018191a9",
            "category" : "plan",
            "link" : "dev\/6050864a72a4f40001843bf7\/605220cd1a5c6e00018191a9.pdf"
          },
          {
            "date" : "2018-02-01T00:00:00.000Z",
            "label" : "Capture d'écran (modifier titre)",
            "_id" : "6050873a72a4f40001843bfa",
            "category" : "leave",
            "link" : "dev\/6050864a72a4f40001843bf7\/6050873a72a4f40001843bfa.png"
          }
        ],
        "totalArea" : 42,
        "rent" : [
          {
            "year" : 2020,
            "value" : 3000
          }
        ],
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "workDetail_commentary" : "Travaux d'aménagement pris en charge par le bailleur à l'entrée dans les lieux",
        "status" : "ENABLED",
        "dateRelease" : [
          {
            "date" : "2021-03-25T00:00:00.000Z",
            "thoughtfulness" : "2m",
            "notice" : "43d"
          }
        ],
        "leaseType" : "OVER_SUBLEASE",
        "workConforme" : "FOR_TENOR",
        "activity" : [
    
        ],
        "lessor" : "Moi (encore)",
        "warranty" : [
    
        ],
        "town" : "LILLE",
        "leaseStart" : "2021-03-18T00:00:00.000Z",
        "accessory" : [
    
        ],
        "rentArea" : "71.43",
        "adress" : "3 place de la gare",
        "leaseDuration" : "4y",
        "work606" : "FOR_RENTER",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2021-02-10T00:00:00.000Z"
          }
        ],
        "todos" : [
          {
            "done" : false,
            "message" : "GED (ajout de document à la création + rappel)",
            "_id" : "605086fe72a4f40001843bf9",
            "date" : "2021-03-16T00:00:00.000Z"
          }
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2020-12-10T00:00:00.000Z"
          }
        ],
        "leaseEnd" : "2021-03-27T00:00:00.000Z",
        "workRenovation" : "FOR_RENTER"
      },
      {
        "leaseStart" : "2007-09-12T00:00:00.000Z",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2010-03-11T00:00:00.000Z"
          }
        ],
        "status" : "ENABLED",
        "zipCode" : "76600",
        "dateRelease" : [
          {
            "date" : "2010-09-11T00:00:00.000Z",
            "thoughtfulness" : "1y",
            "notice" : "6m"
          }
        ],
        "country" : "France",
        "documents" : [
    
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2009-03-11T00:00:00.000Z"
          }
        ],
        "_id" : "62b9ab80a773ef32323338f1",
        "totalArea" : 300,
        "rentArea" : "66.67",
        "activity" : [
    
        ],
        "rent" : [
          {
            "year" : 2022,
            "value" : 20000
          }
        ],
        "town" : "LE HAVRE",
        "todos" : [
    
        ],
        "charges" : [
    
        ],
        "leaseDuration" : "9y",
        "warranty" : [
    
        ],
        "rentYear" : 20000,
        "scope" : {
          "_id" : "FRANCE",
          "label" : "France"
        },
        "leaseEnd" : "2016-09-11T00:00:00.000Z",
        "taxes" : [
    
        ],
        "accessory" : [
    
        ],
        "rentGlobal" : 20000,
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "adress" : "3 rue de Paris",
        "area" : 300,
        "lessor" : "MAQUET",
        "leaseType" : "COMMERCIAL"
      },
      {
        "leaseStart" : "2022-03-16T00:00:00.000Z",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2022-05-30T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2022-03-11T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2022-03-22T00:00:00.000Z"
          }
        ],
        "status" : "ENABLED",
        "zipCode" : "K1K4R4",
        "dateRelease" : [
          {
            "date" : "2022-06-30T00:00:00.000Z",
            "thoughtfulness" : "2m",
            "notice" : "1m"
          },
          {
            "date" : "2022-05-11T00:00:00.000Z",
            "thoughtfulness" : "4m",
            "notice" : "2m"
          },
          {
            "date" : "2022-03-24T00:00:00.000Z",
            "thoughtfulness" : "1d",
            "notice" : "2d"
          }
        ],
        "country" : "Canada",
        "documents" : [
    
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2022-03-30T00:00:00.000Z"
          },
          {
            "index" : 2,
            "value" : "2021-11-11T00:00:00.000Z"
          },
          {
            "index" : 3,
            "value" : "2022-03-21T00:00:00.000Z"
          }
        ],
        "_id" : "622b809da3073000017670ce",
        "totalArea" : 50,
        "rentArea" : "145.10",
        "activity" : [
          {
            "year" : 2022,
            "value" : 200
          }
        ],
        "rent" : [
          {
            "year" : 2022,
            "value" : 5000
          }
        ],
        "town" : "VANIER",
        "todos" : [
    
        ],
        "charges" : [
          {
            "year" : 2022,
            "value" : 2000
          }
        ],
        "leaseDuration" : "5m",
        "warranty" : [
    
        ],
        "rentYear" : 5255,
        "scope" : {
          "_id" : "NORD",
          "label" : "Nord"
        },
        "leaseEnd" : "2022-09-08T00:00:00.000Z",
        "taxes" : [
    
        ],
        "accessory" : [
          {
            "year" : 2022,
            "value" : 55
          }
        ],
        "rentGlobal" : 7255,
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "adress" : "532 Montreal RD",
        "area" : 50,
        "lessor" : "Moi",
        "leaseType" : "OVER"
      },
      {
        "leaseStart" : "2022-10-27T00:00:00.000Z",
        "dateDenunciation" : [
          {
            "index" : 1,
            "value" : "2020-11-08T00:00:00.000Z"
          }
        ],
        "status" : "ENABLED",
        "zipCode" : "59151",
        "dateRelease" : [
          {
            "date" : "2022-11-08T00:00:00.000Z",
            "thoughtfulness" : "4m",
            "notice" : "2y"
          }
        ],
        "country" : "France",
        "documents" : [
          {
            "link" : "dev\/635a9430c77d2f9f9d4bb543\/635a94d1b0388c8c292ddb3b.JPEG",
            "label" : "Carte",
            "_id" : "635a94d1b0388c8c292ddb3b",
            "category" : "etat",
            "date" : "2022-10-29T00:00:00.000Z"
          }
        ],
        "dateAlert" : [
          {
            "index" : 1,
            "value" : "2020-07-08T00:00:00.000Z"
          }
        ],
        "_id" : "635a9430c77d2f9f9d4bb543",
        "totalArea" : 23,
        "rentArea" : "101.74",
        "activity" : [
    
        ],
        "rent" : [
          {
            "year" : 2022,
            "value" : 2340
          }
        ],
        "town" : "ARLEUX",
        "todos" : [
    
        ],
        "charges" : [
    
        ],
        "leaseDuration" : "2m",
        "warranty" : [
    
        ],
        "rentYear" : 2340,
        "scope" : {
          "_id" : "NORD",
          "label" : "Nord"
        },
        "leaseEnd" : "2023-03-23T00:00:00.000Z",
        "taxes" : [
    
        ],
        "accessory" : [
    
        ],
        "rentGlobal" : 2340,
        "entity" : {
          "_id" : "5ff776579a5a5e24600708dd",
          "adress" : "3 Avenue d'Argenteuil",
          "country" : "France",
          "status" : "ENABLED",
          "town" : "Asnières-sur-seine",
          "label" : "BXP",
          "zipCode" : "92600"
        },
        "adress" : "22 rue Jacques duclos",
        "area" : 23,
        "lessor" : "Moi meme",
        "leaseType" : "COMMERCIAL"
      }
    ]

    console.log(params);
    await new Promise(resolve => setTimeout(resolve, 1000));
    return leases;

    // const request = {
    //   params,
    //   method: 'GET',
    //   url: '/api/leases',
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async patchLease(id, data) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return { id, data };

    // const request = {
    //   data,
    //   method: 'PATCH',
    //   url: `/api/leases/${id}`,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async postLease(data) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return data;

    // const request = {
    //   data,
    //   method: 'POST',
    //   url: '/api/leases',
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async deleteLease(leaseId) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return leaseId;

    // const request = {
    //   method: 'DELETE',
    //   url: `/api/leases/${leaseId}`,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }

  async uploadFile(leaseId, documentId, file) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return { leaseId, documentId, file };

    // // Get urlSigned
    // const requestUrlSigned = {
    //   method: 'POST',
    //   url: '/api/documents',
    //   data: {
    //     leaseId,
    //     documentId,
    //     extension: file.name.split('.').pop(),
    //   },
    // };

    // const responseUrlSigned = await this.apiClient.sendRequest(requestUrlSigned);

    // if (responseUrlSigned.status === 200 && responseUrlSigned.data) {
    //   // Post File
    //   const url = responseUrlSigned.data.uploadPath;
    //   await this.uploadService.postFile(url, file);
    // }

    // return responseUrlSigned.data;
  }

  async readFile(path) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return path;

    // const request = {
    //   method: 'GET',
    //   url: `/api/documents/${path}`,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }
}

export default LeaseService;
