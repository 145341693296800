class SupportService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async sendSupport(context) {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return context;

    // const request = {
    //   method: 'POST',
    //   url: '/iam/support',
    //   data: context,
    // };

    // const response = await this.apiClient.sendRequest(request);
    // return response.data;
  }
}

export default SupportService;
