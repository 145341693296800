import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersistence from 'vuex-persist'

import alertModule from './alert';

import userModule from './user';
import usersModule from './users';
import leasesModule from './leases';
import rolesModule from './roles';
import scopesModule from './scopes';
import entitiesModule from './entities';
import listModule from './list';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alertModule,
    userModule,
    usersModule,
    leasesModule,
    rolesModule,
    scopesModule,
    entitiesModule,
    listModule,
  },
  state: {
    loading: true,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initialize(context) {
      await context.dispatch('getUser');
      await context.dispatch('getUsers');
      await context.dispatch('getLeases');
      await context.dispatch('getRoles');
      await context.dispatch('getScopes');
      await context.dispatch('getEntities');
      context.commit('SET_LOADING', false);
    },
  },
  /* plugins: [new VuexPersistence().plugin] */
});
