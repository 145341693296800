<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({}),
  mounted() {
    if (localStorage.getItem('BAUX_THEME_DARK')) {
      this.$vuetify.theme.dark = localStorage.getItem('BAUX_THEME_DARK');
    }
  },
};
</script>

<style>
.row {
  /* Vuetify update v2.4 */
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>
