import Vue from 'vue';
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
// import VueGtag from "vue-gtag";
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/vue-i18n';
import store from './store';
import './filters';

// import config from './config';
// import packageApp from '../package.json';

Vue.config.productionTip = false;

// Sentry.init({
//   Vue,
//   release: `baux@${packageApp.version}`,
//   dsn: config.sentry.dsn,
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router)
//     }),
//   ],
//   tracesSampleRate: 0.2,
// });

// Vue.use(VueGtag, {
//   config: { id: config.analytics.trakingId }
// });

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
